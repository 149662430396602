import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import LeftArrow from '../img/icons/LeftArrow.inline.svg'
import RightArrow from '../img/icons/RightArrow.inline.svg'

const LinkBase = styled.a({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
  position: 'absolute',
  width: '4.75rem',
  top: '0',
  bottom: '1.3rem',
  outline: 'none',
  cursor: 'pointer',
  transition: `color 300ms ease-in-out, background-color 300ms ease-in-out, 300ms transform ease-in-out, 300ms opacity ease-in-out`,
  opacity: 0,
}, ({visible, direction}) => {
  let x
  if (direction === 'prev') {
    x = `-4rem`
  } else {
    x = `4rem`
  }
  return {
    opacity: visible ? 1 : 0,
    transform: visible ? `translateX(0)` : `translateX(${x})`
  }
})

const Arrow = styled.div({
  width: '2rem',
  height: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [`svg`]: {
    width: `auto`,
    height: `auto`
  }
})

const PrevLink = styled(LinkBase)({ left: 0 })

const NextLink = styled(LinkBase)({ right: 0 })

const ScreenReaderText = styled.span({
  position: 'absolute',
  left: '-999em'
})

const Arrows = ({ handlePrevClick, handleNextClick, showPrev, showNext }) => {

  return (
    <React.Fragment>
      <PrevLink
        onClick={ handlePrevClick }
        role={ 'link' }
        tabIndex={ '0' }
        visible={showPrev}
        direction={'prev'}
      >
        <Arrow>
          <LeftArrow alt='Prev' />
        </Arrow>
        <ScreenReaderText>{ 'Prev' }</ScreenReaderText>
      </PrevLink>
      <NextLink
        onClick={ handleNextClick }
        role={ 'link' }
        tabIndex={ '0' }
        visible={showNext}
        direction={'next'}
      >
        <Arrow>
          <RightArrow alt='Next' />
        </Arrow>
        <ScreenReaderText>{ 'Next' }</ScreenReaderText>
      </NextLink>
    </React.Fragment>
  )
}

Arrows.propTypes = {
  handleNextClick: PropTypes.func.isRequired,
  handlePrevClick: PropTypes.func.isRequired,
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool
}

export default Arrows
