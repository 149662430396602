
const getSizes = (obj, max, cover) => {
    if (obj) {
      const { url, name, aspectRatio } = obj
      const maxWidth = max || 1600

      const fluidSizes = []
      fluidSizes.push(Math.round(maxWidth / 3))
      fluidSizes.push(Math.round(maxWidth / 2))
      fluidSizes.push(Math.round(maxWidth / 1.5))
      fluidSizes.push(maxWidth)
      // can't be greater then 3000 pixels
      maxWidth * 1.5 < 3000 && fluidSizes.push(Math.round(maxWidth * 1.5))

      const images = fluidSizes.map(width => {
        return {
          src: encodeURI(`${url}-/stretch/off/-/resize/${width}x/${name}`),
          side: cover && width <= 800 ? `h` : `w`,
          width
        }
      })

      const srcSet = images
        .map(image => `${image.src} ${Math.round(image.width)}${image.side}`)
        .join(`,\n`)

      const portrait = aspectRatio < 1 ? `calc(100vw * ${aspectRatio})` : '100vw'
      const landscape = `calc(80vh * ${aspectRatio})`

      return {
        aspectRatio: aspectRatio,
        base64: `${url}-/stretch/off/-/resize/100x/${name}`, // psuedo base64
        sizes: `(orientation: portrait) ${portrait}, (max-width: ${maxWidth * 1.5}px) ${landscape}, ${maxWidth}px`,
        src: `${url}-/stretch/off/-/resize/x${Math.round(maxWidth / 3)}/${name}`, // fallbackSrc
        srcSet
      }
    }

    return {}
  }

  export default getSizes