import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'
import { EASE } from '../utils/presets'

// The duration for each phase of the transition
// So the total duration will be _twice_ this
const timeout = 300;

const slideStyles = {
  entering: {
    // without position absolute, the exit animation appears short-circuited
    position: 'absolute',
    opacity: 0,
    transform: `translateX(100%)`,
  },
  entered: {
    transition: `opacity ${timeout}ms ${EASE}, transform ${timeout * 2}ms ${EASE}`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-out, transform ${timeout * 2}ms ease-out`,
    opacity: 0,
    transform: `translateX(-100%)`,
  },
};

const fadeStyles = {
  entering: {
    position: 'absolute',
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  }
}

const Transition = ({ children, location }) => (
  <TransitionGroup>
    <ReactTransition key={location.pathname} timeout={timeout}>
      {(status) => {

        return (
          <div
            style={location.pathname.includes('/about/') || location.pathname.includes('/404/') ? fadeStyles[status] : slideStyles[status]}
          >
            {children}
          </div>
      )}}
    </ReactTransition>
  </TransitionGroup>
);

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

export default Transition
