import React from 'react'

export const MouseContext = React.createContext({
  target: false
})


export function mouseReducer(state, action) {
  switch (action.type) {
    case 'enter':
      return {target: action.item};
    case 'exit':
      return {target: false};
    default:
      throw new Error();
  }
}

export function waypointReducer(state, action) {
  switch (action.type) {
    case 'header':
      return 'header';
    case 'main':
      return 'main';
    case 'footer':
      return 'footer';
    default:
      throw new Error();
  }
}

export const SizeContext = React.createContext({wHeight: 0, wWidth: 0})

export const WaypointContext = React.createContext("header")

export const MouseDispatch = React.createContext(() => {})

export const MouseCordsContext = React.createContext("")

export const MouseCordsDispatch = React.createContext({clientX: 0, clientY: 0})

export const NavDispatch = React.createContext(() => {})

// consider using a render prop composition to flatten these