import { graphql, useStaticQuery } from 'gatsby'

const usePortfolioIndex = () => {
  const data = useStaticQuery(graphql`
    query PortfolioIndexQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          sharingImage {
            url
            name
          }
          index {
            thumbnail {
              url
              aspectRatio
              name
            }
            project {
              fields {
                slug
              }
              frontmatter {
                title
                subtitle
                slug
              }
            }
            title
          }
        }
      }
    }
  `)
  return data
}

export default usePortfolioIndex
