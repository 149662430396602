import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { throttle } from 'lodash'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import usePortfolioIndex from '../hooks/usePortfolioIndex'
import Arrows from './Arrows'
import IndexItem from './PortfolioIndexItem'
import { MIN_TABLET_MQ } from '../utils/presets'

const Header = styled.header({
  position: 'relative',
  overflow: 'hidden'
})

const ScrollWrapper = styled.nav({
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  overflowScrolling: 'touch',
  WebkitOverflowScrolling: 'touch', // iOS
  MsOverflowStyle: '-ms-autohiding-scrollbar', // Windows
  '::-webkit-scrollbar': { display: 'none' }, // Chrome etc
  scrollbarWidth: 'none', // FF
  height: '146px',
  paddingTop: '1rem',
  scrollSnapType: `x mandatory`,
  [MIN_TABLET_MQ]: {
    marginLeft: '180px'
  }
})

const IndexItemWrapper = styled.div({
  flex: '0 0 auto',
  color: '#000',
  padding: '0 0 0 1.5rem',
  scrollSnapAlign: `start`,
  [MIN_TABLET_MQ]: {
    padding: '0 1.5rem',
  }
}, ({ isFirst }) => {
  let sticky
  if (isFirst) {
    sticky = {
      display: `none`,
      [MIN_TABLET_MQ]: {
        display: `inline-block`,
        position: `absolute`,
        left: 0,
        backgroundColor: `#FFF`,
        zIndex: 999
      }
    }
  }
  return {
    ...sticky
  }
})

const PortfolioIndex = ({ onIndexItemClick, wWidth, pathname }) => {
  const data = usePortfolioIndex()
  const { frontmatter = {} } = data.markdownRemark
  const { index } = frontmatter

  // MQs
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const scrollRef = useRef()
  const lastCard = useRef()

  // these only calc on render
  // this is static unless the window resizes (will it get updated? probably need to update it explicitly for reliability)
  const scrollWidth = scrollRef.current && scrollRef.current.scrollWidth ? scrollRef.current.scrollWidth : 0

  const [scrollLeft, setScrollleft] = useState(scrollRef.current && scrollRef.current.scrollLeft ? scrollRef.current.scrollLeft : 0);

  const calcScroll = (newScrollLeft) => {
    setScrollleft(newScrollLeft)
  }

  const handleScroll = useMemo(() => {
    const throttled = throttle(e => calcScroll(e.target.scrollLeft), 500);
    return e => {
      e.persist();
      return throttled(e);
    };
  }, []);

  const handleNextClick = () => {
    let left = scrollWidth // scroll to the end by default
    if (scrollLeft < scrollWidth) {
      left = scrollLeft + wWidth
    }

    scrollRef.current.scrollTo({
      top: 0,
      left,
      behavior: `smooth`
    })
  }
  const handlePrevClick = () => {
    let left = 0 // scroll to beginning by default
    if (scrollLeft > 0) {
      left = scrollLeft - wWidth
    }
    scrollRef.current.scrollTo({
      top: 0,
      left,
      behavior: `smooth`
    })
  }

  const handleIndexItemClick = (isFirst) => {
    if (isFirst) {
      if (scrollRef.current.scrollLeft !== 0) {
        scrollRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: `smooth`
        })
      }
      setTimeout(() => {
        if (onIndexItemClick) onIndexItemClick()
      }, 1750)
    } else {
      if (onIndexItemClick) onIndexItemClick()
    }
  }

  return (
    <Header>
      <ScrollWrapper ref={scrollRef} onScroll={handleScroll}>
        {index &&
          index.map(({ thumbnail, project, title }, count) => {
            if (project) {
              const { fields, frontmatter } = project
              // support both filename slug and frontmatter slug
              const slug = frontmatter.slug ? `/portfolios/${frontmatter.slug}/` : fields.slug
              let ref
              if (count === index.length - 1) {
                ref = lastCard
              }
              return (
                <IndexItemWrapper key={slug} ref={ref} isFirst={count === 0}>
                  <IndexItem
                    count={count}
                    onIndexItemClick={handleIndexItemClick}
                    pathname={pathname}
                    title={title || frontmatter.title}
                    thumbnail={thumbnail}
                    slug={slug}
                  />
                </IndexItemWrapper>
              )
            } else {
              return <div/>
            }
          })}
      </ScrollWrapper>
      { !isTabletOrMobile &&
        <Arrows
          showPrev={scrollLeft > 0}
          showNext={scrollLeft < (scrollWidth - wWidth) }
          handleNextClick={ handleNextClick }
          handlePrevClick={ handlePrevClick }
        />
      }
    </Header>
  )
}

PortfolioIndex.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        index: PropTypes.array
      })
    }),
  }),
  wWidth: PropTypes.number,
  onIndexItemClick: PropTypes.func.required,
  pathname: PropTypes.pathname
}

export default PortfolioIndex

