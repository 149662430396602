import React from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/core'
import useSiteMetadata from '../hooks/useSiteMetadata'
import Transition from '../components/Transition'
import Wrapper from '../components/Wrapper'
import './fonts.css'

function Layout({children, location, data }) {
  const { title } = useSiteMetadata()
  return (
    <React.Fragment>
      <Global
        styles={css`
          html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
          html {
            scroll-behavior: smooth;
            height: 100%;
            overflow: hidden;
            font-size: 14px;
          }
          body {
            font-family: "Selecta-Regular", "Helvetica Neue", "Helvetica", Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-weight: normal;
            letter-spacing: 0.15px;
            line-height: 1.25rem;
          }
          h1,h2,h3,h5,h6 {
            font-size: 1rem;
            line-height: 1.25rem;
          }
          a {
            color: #000;
            text-decoration: none;
          }
          a:hover {
            text-decoration: underline;
          }
          video {
            outline: none;
          }
        `}
      />
      <Wrapper title={title} pathname={location.pathname} data={data}>
        <Transition location={location}>
          {children}
        </Transition>
      </Wrapper>
    </React.Fragment>
  )
}

Layout.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default Layout
