import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { MIN_TABLET_MQ } from '../utils/presets'

const ContentWrapper = styled.div({
  cursor: 'auto',
  maxWidth: '33rem',
  padding: '0 1.5rem',
  [MIN_TABLET_MQ]: {
    padding: '0 1.5rem',
  },
  'h5': {
    fontSize: '1rem',
    marginBottom: '8px'
  },
  'p': {
    marginBottom: '32px',
    lineHeight: '21px'
  }
})


export const HTMLContent = ({ content, ...rest }) => {
  return (
    <ContentWrapper
      dangerouslySetInnerHTML={{ __html: content }}
      {...rest}
    />
  )
}

HTMLContent.propTypes = {
  content: PropTypes.node
}
