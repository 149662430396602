import { useEffect, useState, useMemo } from 'react';
import { throttle } from 'lodash'

function useMouseMove(cursor, wrapper, size, hasInfo, isAbout) {
  const [direction, setDirection] = useState("");
  let prevDirection = direction;

  const moveHandler = useMemo(() => {
    const throttled = throttle(e => {
      const { clientX: x, clientY: y } = e
      let nextDirection
      if (y < (size.wHeight / 4) || isAbout) {
        // Mouse position in the top quarter of the screen
        nextDirection = "up"
      } else if (y > (size.wHeight * 0.75) && hasInfo) {
        // Mouse position in the bottom quarter of the screen
        nextDirection = "down"
      } else if (x > (size.wWidth / 2)) {
        // Mouse position in the right half of the screen
        nextDirection = "next"
      } else if (x < (size.wWidth / 2)) {
        // Mouse position in the left half of the screen
        nextDirection = "prev"
      }

      if (prevDirection !== nextDirection) {
        setDirection(nextDirection);
        prevDirection = nextDirection;
      }

    }, 250);
    return (e, size) => {
      // e.persist();
      // persist doesn't exist because the handler is async?
      // https://stackoverflow.com/a/38151212/497344
      // is it ok to NOT use persist?
      if (cursor && cursor.current && cursor.current.style) {
        // Update cursor element's position without a throttle
        // Center cursor: 1.75rem width (14px = 1rem) = 24,5
        cursor.current.style.left = `${e.clientX - (24.5 / 2)}px`
        cursor.current.style.top = `${e.clientY - (24.5 / 2)}px`
      }
      return throttled(e, size);
    };
  }, [hasInfo]);

  useEffect(() => {
    wrapper.current.addEventListener('mousemove', (evt) => moveHandler(evt, size));
    return () => {
      wrapper.current.removeEventListener('mousemove', moveHandler);
    };
  }, [size, hasInfo]);

  return direction;
}

export default useMouseMove