import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import getSizes from '../utils/getSizes'
import { MIN_TABLET_MQ } from '../utils/presets'

const Card = styled(Link)({
  // maxWidth: '130px',
  [MIN_TABLET_MQ]: {
    [`&:hover`]: {
      [`span`]: {
        // borderBottom: `1px solid #000`
        textDecorationLine: `underline`,
      }
    }
  }
})

const CardCaption = styled.figcaption({
  padding: '0.75rem 0 0 0',
  lineHeight: `1.25rem`,
  textAlign: 'left'
}, ({ isActive, isFirst }) => {
  return {
    [`span`]: {
      display: `inline-block`,
      // fontFamily: isFirst && `Selecta-Medium`,
      textTransform: isFirst && `uppercase`,
      textUnderlineOffset: `0.25rem`,
      textDecorationLine: isActive ? `underline` : `none`,
      // borderBottom: isActive ? `1px solid #000` : `1px solid transparent`
    }
  }
})

const IndexItem = ({
  count,
  onIndexItemClick,
  title,
  slug,
  pathname,
  thumbnail
}) => {
  const image = getSizes(thumbnail[0], 300)
  const { aspectRatio } = image
  const isFirst = count === 0
  const isActive = decodeURI(pathname) === slug || (count === 0 && pathname === '/')
  const handleOnIndexItemClick = () => {
    if (isActive || isFirst) {
      if (onIndexItemClick) { onIndexItemClick(isFirst) }
    }
  }

  return (
    <Card
      to={count === 0 ? '/' : slug}
      onClick={handleOnIndexItemClick}
      isFirst={isFirst}
    >
      <div
        // outerWrapper
        css={{
          zIndex: 0,
          position: `relative`,
          width: 112 * aspectRatio + 'px',
          display: 'block',
          margin: '0 auto'
        }}
      >
        <div
          // innerWrapper
          css={{
            position: `relative`,
            overflow: `hidden`,
            zIndex: 1
          }}
        >
          <div
            // preserveRatio
            css={{
              width: `100%`,
              paddingBottom: `${100 / aspectRatio}%`,
            }}
          >
            <img
              // alt={alt}
              // title={title}
              {...image}
              css={{
                position: `absolute`,
                top: 0,
                left: 0,
                width: `100%`,
                height: `100%`,
                objectFit: `contain`,
                objectPosition: `center`,
                fontFamily: `'object-fit: 'contain'; object-position: 'center';'`
              }}
            />
          </div>
        </div>
      </div>
      <CardCaption isActive={isActive} isFirst={isFirst}>
        <span>{isFirst ? `Reto Schmid` : title}</span>
      </CardCaption>
  </Card>
  )
}

IndexItem.propTypes = {
  aspectRatio: PropTypes.number,
  count: PropTypes.number,
  onIndexItemClick: PropTypes.func,
  pathname: PropTypes.string,
  title: PropTypes.string,
  sizes: PropTypes.object,
  slug: PropTypes.string,
  thumbnail: PropTypes.array
}

export default React.memo(IndexItem)
